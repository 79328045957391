exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projelerimiz-diger-projeler-altunizade-butik-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/altunizade-butik.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-altunizade-butik-js" */),
  "component---src-pages-projelerimiz-diger-projeler-gungoren-ofis-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/gungoren-ofis.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-gungoren-ofis-js" */),
  "component---src-pages-projelerimiz-diger-projeler-index-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/index.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-index-js" */),
  "component---src-pages-projelerimiz-diger-projeler-kosuyolu-guzellik-merkezi-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/kosuyolu-guzellik-merkezi.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-kosuyolu-guzellik-merkezi-js" */),
  "component---src-pages-projelerimiz-diger-projeler-maslak-eczane-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/maslak-eczane.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-maslak-eczane-js" */),
  "component---src-pages-projelerimiz-diger-projeler-merter-plaza-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/merter-plaza.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-merter-plaza-js" */),
  "component---src-pages-projelerimiz-diger-projeler-saint-denis-renovasyon-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/saint-denis-renovasyon.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-saint-denis-renovasyon-js" */),
  "component---src-pages-projelerimiz-diger-projeler-sariyer-ofis-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/sariyer-ofis.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-sariyer-ofis-js" */),
  "component---src-pages-projelerimiz-diger-projeler-taksim-eczane-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/taksim-eczane.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-taksim-eczane-js" */),
  "component---src-pages-projelerimiz-diger-projeler-taksim-suits-js": () => import("./../../../src/pages/projelerimiz/diger-projeler/taksim-suits.js" /* webpackChunkName: "component---src-pages-projelerimiz-diger-projeler-taksim-suits-js" */),
  "component---src-pages-projelerimiz-index-js": () => import("./../../../src/pages/projelerimiz/index.js" /* webpackChunkName: "component---src-pages-projelerimiz-index-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-atasehir-daire-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/atasehir-daire.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-atasehir-daire-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-bomonti-penthouse-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/bomonti-penthouse.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-bomonti-penthouse-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-burhaniye-konaklari-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/burhaniye-konaklari.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-burhaniye-konaklari-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-florya-penthouse-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/florya-penthouse.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-florya-penthouse-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-index-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/index.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-index-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-kosuyolu-penthouse-1-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/kosuyolu-penthouse-1.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-kosuyolu-penthouse-1-js" */),
  "component---src-pages-projelerimiz-penthouse-daire-kosuyolu-penthouse-2-js": () => import("./../../../src/pages/projelerimiz/penthouse-daire/kosuyolu-penthouse-2.js" /* webpackChunkName: "component---src-pages-projelerimiz-penthouse-daire-kosuyolu-penthouse-2-js" */),
  "component---src-pages-projelerimiz-restaurant-kafe-cekmekoy-restoran-js": () => import("./../../../src/pages/projelerimiz/restaurant-kafe/cekmekoy-restoran.js" /* webpackChunkName: "component---src-pages-projelerimiz-restaurant-kafe-cekmekoy-restoran-js" */),
  "component---src-pages-projelerimiz-restaurant-kafe-index-js": () => import("./../../../src/pages/projelerimiz/restaurant-kafe/index.js" /* webpackChunkName: "component---src-pages-projelerimiz-restaurant-kafe-index-js" */),
  "component---src-pages-projelerimiz-restaurant-kafe-kosuyolu-kafe-2-js": () => import("./../../../src/pages/projelerimiz/restaurant-kafe/kosuyolu-kafe-2.js" /* webpackChunkName: "component---src-pages-projelerimiz-restaurant-kafe-kosuyolu-kafe-2-js" */),
  "component---src-pages-projelerimiz-restaurant-kafe-kosuyolu-kafe-js": () => import("./../../../src/pages/projelerimiz/restaurant-kafe/kosuyolu-kafe.js" /* webpackChunkName: "component---src-pages-projelerimiz-restaurant-kafe-kosuyolu-kafe-js" */),
  "component---src-pages-projelerimiz-restaurant-kafe-paris-cafe-js": () => import("./../../../src/pages/projelerimiz/restaurant-kafe/paris-cafe.js" /* webpackChunkName: "component---src-pages-projelerimiz-restaurant-kafe-paris-cafe-js" */),
  "component---src-pages-projelerimiz-villa-index-js": () => import("./../../../src/pages/projelerimiz/villa/index.js" /* webpackChunkName: "component---src-pages-projelerimiz-villa-index-js" */),
  "component---src-pages-projelerimiz-villa-kirecburnu-villa-js": () => import("./../../../src/pages/projelerimiz/villa/kirecburnu-villa.js" /* webpackChunkName: "component---src-pages-projelerimiz-villa-kirecburnu-villa-js" */),
  "component---src-pages-projelerimiz-villa-paris-villa-js": () => import("./../../../src/pages/projelerimiz/villa/paris-villa.js" /* webpackChunkName: "component---src-pages-projelerimiz-villa-paris-villa-js" */),
  "component---src-pages-projelerimiz-villa-yalikavak-villa-sitesi-js": () => import("./../../../src/pages/projelerimiz/villa/yalikavak-villa-sitesi.js" /* webpackChunkName: "component---src-pages-projelerimiz-villa-yalikavak-villa-sitesi-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

